<template>
 
  <div v-if="isAuthenticated">
    <h1 class="page-title" style="color: bisque;">MES DEMANDES D'OM</h1>
    <button type="button" class="btn btn-primary" @click="showModal = true">
      <i class="fa fa-plus"></i> Faire une demande d'OM
    </button>

    <!-- Search Input -->
    <div class="mb-3">
      <input 
        type="text" 
        v-model="searchQuery" 
        class="form-control" 
        placeholder="Rechercher des demandes d'OM..."
      />
    </div>
    <h2>Vos demandes d'om</h2>

    <div class="table-responsive p-0">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-10" @click="sortBy('lieu_a_se_rendre')" style="padding-left: 20px;">Lieu à se rendre</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortBy('lieu_de_provenance')" style="padding-left: 20px;">Lieu de provenance</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortBy('objet')" style="padding-left: 20px;">Objet</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortBy('date_de_depart')" style="padding-left: 50px;">Date de départ</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortBy('date_de_retour')" style="padding-left: 50px;">Date de retour</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="demande in filteredAndSortedDemandes" :key="demande.id">
        <td class="text-xs font-weight-bold mb-0 text-center"> {{demande.lieu_a_se_rendre}}</td>
        <td class="text-xs font-weight-bold mb-0 text-center">{{demande.lieu_de_provenance}}</td>
        <td class="text-xs font-weight-bold">{{truncateText(demande.objet)}}</td>
        <td class="align-middle text-center">{{ formatDate(demande.date_de_depart) }}</td>
        <td class="align-middle text-center"><span class="text-secondary text-xs font-weight-bold">{{ formatDate(demande.date_de_retour)}}</span></td>
       
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal -->
    <div class="modal" tabindex="-1" v-if="showModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Créer Demande OM</h5>
            <button type="button" class="btn-close" @click="showModal = false" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
              <!-- Autres champs de formulaire ici ... -->
              <div class="mb-3">
                <label for="lieu_a_se_rendre" class="form-label">Lieu à se rendre</label>
                <input type="text" id="lieu_a_se_rendre" v-model="form.lieu_a_se_rendre" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="lieu_de_provenance" class="form-label">Lieu de provenance</label>
                <input type="text" id="lieu_de_provenance" v-model="form.lieu_de_provenance" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="objet" class="form-label">Pour</label>
                <input type="text" id="objet" v-model="form.objet" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="nombre_de_jours" class="form-label">Nombre de jours</label>
                <input type="number" id="nombre_de_jours" v-model="form.nombre_de_jours" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="date_de_depart" class="form-label">Date de départ</label>
                <input type="date" id="date_de_depart" v-model="form.date_de_depart" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="date_de_retour" class="form-label">Date de retour</label>
                <input type="date" id="date_de_retour" v-model="form.date_de_retour" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="transport_en_commun" class="form-label">Transport en commun</label>
                <input type="checkbox" id="transport_en_commun" v-model="form.transport_en_commun" class="form-check-input">
              </div>
              <div class="mb-3">
                <label for="tdr" class="form-label">TDR</label>
                <input type="file" id="tdr" @change="handleFileUpload" class="form-control">
              </div>

              <!-- Recherche de participants -->
              <div class="mb-3">
                <label for="search" class="form-label">Rechercher Participant</label>
                <input type="text" v-model="participantSearch" @input="filterParticipants" class="form-control" placeholder="Rechercher...">
              </div>

              <!-- Liste des participants non sélectionnés -->
              <div class="mb-3">
                <label for="inactive-participants" class="form-label">Participants Inactifs</label>
                <div v-for="participant in availableParticipants" :key="participant.id" class="form-check">
                  <input type="checkbox" :id="'inactive-' + participant.id" :value="participant.id" v-model="selectedInactiveParticipants" class="form-check-input">
                  <label :for="'inactive-' + participant.id" class="form-check-label">{{ participant.noms }}</label>
                </div>
                <button type="button" @click="activateParticipants" class="btn btn-secondary">Ajouter</button>
              </div>

              <!-- Liste des participants sélectionnés -->
              <div class="mb-3">
                <label for="active-participants" class="form-label">Participants Actifs</label>
                <div v-for="participant in activeParticipants" :key="participant.id" class="form-check">
                  <input type="checkbox" :id="'active-' + participant.id" :value="participant.id" v-model="selectedActiveParticipants" class="form-check-input">
                  <label :for="'active-' + participant.id" class="form-check-label">{{ participant.noms }}</label>
                </div>
                <button type="button" @click="deactivateParticipants" class="btn btn-secondary">Retirer</button>
              </div>

              <button type="submit" class="btn btn-primary">Créer</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>
<!---<script>
import api from '@/services/api';

export default {
  name: 'DemandeOmForm',
  data() {
    return {
      showModal: false,
      form: {
        participants: [],
        lieu_a_se_rendre: '',
        lieu_de_provenance: '',
        objet: '',
        date_de_depart: '',
        date_de_retour: '',
        nombre_de_jours:'',
        transport_en_commun: false,
        //tdr:''
        //numero_vehicule: '',
        //nom_du_conducteur: '',
        utilisateur_id: this.$store.state.user.id,
      },
      participants: [],
      participantSearch: '',
      selectedInactiveParticipants: [],
      selectedActiveParticipants: [],
      demandes: []
    };
  },
  
  computed: {
    availableParticipants() {
      return this.participants.filter(participant =>
        participant.noms.toLowerCase().includes(this.participantSearch.toLowerCase()) &&
        !this.form.participants.includes(participant.id)
      );
    },
    activeParticipants() {
      return this.participants.filter(participant =>
        this.form.participants.includes(participant.id)
      );
    }
  },
  async mounted() {
    await this.fetchParticipants();
    //await this.fetchDemandes();
  },
  async created() {
    this.fetchDemandes();
  },
  methods: {
    async fetchDemandes() {
      try {
        console.log("USER_ID",this.form.utilisateur_id)
        const response = await api.getDemandesByUser(this.form.utilisateur_id);
        this.demandes = response.data;
        console.log("DEMANDES",this.demandes)
      } catch (error) {
        console.error('Error fetching demandes:', error);
      }
    },
    async fetchParticipants() {
      try {
        const response = await api.getParticipants();
        this.participants = response.data;
      } catch (error) {
        console.error('Failed to fetch participants:', error);
      }
    },
    activateParticipants() {
      this.form.participants.push(...this.selectedInactiveParticipants);
      console.log("this.form.participants.push",this.form.participants)
      this.selectedInactiveParticipants = [];
    },
    deactivateParticipants() {
      this.form.participants = this.form.participants.filter(id => !this.selectedActiveParticipants.includes(id));
      this.selectedActiveParticipants = [];
    },
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    async handleSubmit() {
      try {
        console.log("This form", this.form);
        const formData = new FormData();
        Object.keys(this.form).forEach(key => {
          if (key === 'participants') {
            this.form[key].forEach(participant => {
              formData.append('participants[]', participant);
            });
          } else {
            formData.append(key, this.form[key]);
          }
        });

        if (this.selectedFile) {
          console.log("Selected File", this.selectedFile);
          formData.append('tdr', this.selectedFile);
        }

        // Inspecting the FormData values
        for (let pair of formData.entries()) {
          console.log(pair[0] + ': ' + pair[1]);
        }

        const response = await api.createDemandeOm(formData);
        console.log('Demande OM created:', response.data);
        console.log("USER_ID_BIS",this.form.utilisateur_id)
        this.fetchDemandes();
        this.resetForm();
        console.log("USER_ID_BIS_1",this.form.utilisateur_id)
        //this.fetchDemandes();
        this.showModal = false;
      } catch (error) {
        console.error('Failed to create Demande OM:', error);
      }
    },
    resetForm() {
      this.form = {
        participants: [],
        lieu_a_se_rendre: '',
        lieu_de_provenance: '',
        objet: '',
        date_de_depart: '',
        date_de_retour: '',
        transport_en_commun: false,
        //tdr:''
       // numero_vehicule: '',
        //nom_du_conducteur: '',
      };
      this.selectedFile = null;
      this.participantSearch = '';
      this.selectedInactiveParticipants = [];
      this.selectedActiveParticipants = [];
    },
    
  }
};
</script>-->

<script>
import api from '@/services/api';

export default {
  name: 'DemandeOmForm',
  data() {
    return {
      showModal: false,
      searchQuery: '', // For the search input
      sortKey: '', // For sorting
      sortOrder: 1, // 1 for ascending, -1 for descending
      form: {
        participants: [],
        lieu_a_se_rendre: '',
        lieu_de_provenance: '',
        objet: '',
        date_de_depart: '',
        date_de_retour: '',
        nombre_de_jours: '',
        transport_en_commun: false,
        utilisateur_id: this.$store.state.user.id,
      },
      participants: [],
      participantSearch: '',
      selectedInactiveParticipants: [],
      selectedActiveParticipants: [],
      demandes: [],
    };
  },
  
  computed: {
   
    isAuthenticated() {
          return this.$store.getters.isAuthenticated;
        },
        user() {
          return this.$store.getters.getUser;
        },
        token() {
          return this.$store.getters.getToken;
        },
        availableParticipants() {
      return this.participants.filter(participant =>
        participant.noms.toLowerCase().includes(this.participantSearch.toLowerCase()) &&
        !this.form.participants.includes(participant.id)
      );
    },
    activeParticipants() {
      return this.participants.filter(participant =>
        this.form.participants.includes(participant.id)
      );
    },
    filteredAndSortedDemandes() {
      let filtered = this.demandes.filter(demande => {
        return (
          demande.lieu_a_se_rendre.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          demande.lieu_de_provenance.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          demande.objet.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });

      if (this.sortKey) {
        filtered.sort((a, b) => {
          let result = 0;
          if (a[this.sortKey] < b[this.sortKey]) result = -1;
          if (a[this.sortKey] > b[this.sortKey]) result = 1;
          return result * this.sortOrder;
        });
      }

      return filtered;
    }
  },
  async mounted() {
    await this.fetchParticipants();
    //await this.fetchDemandes();
  },
  
  async created() {
    this.fetchDemandes();
  },
  
  methods: {

    async fetchParticipants() {
      try {
        const response = await api.getParticipants();
        this.participants = response.data;
      } catch (error) {
        console.error('Failed to fetch participants:', error);
      }
    },
    activateParticipants() {
      this.form.participants.push(...this.selectedInactiveParticipants);
      console.log("this.form.participants.push",this.form.participants)
      this.selectedInactiveParticipants = [];
    },
    deactivateParticipants() {
      this.form.participants = this.form.participants.filter(id => !this.selectedActiveParticipants.includes(id));
      this.selectedActiveParticipants = [];
    },

    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    async handleSubmit() {
      try {
        console.log("This form", this.form);
        const formData = new FormData();
        Object.keys(this.form).forEach(key => {
          if (key === 'participants') {
            this.form[key].forEach(participant => {
              formData.append('participants[]', participant);
            });
          } else {
            formData.append(key, this.form[key]);
          }
        });

        if (this.selectedFile) {
          console.log("Selected File", this.selectedFile);
          formData.append('tdr', this.selectedFile);
        }

        // Inspecting the FormData values
        for (let pair of formData.entries()) {
          console.log(pair[0] + ': ' + pair[1]);
        }

        const response = await api.createDemandeOm(formData);
        console.log('Demande OM created:', response.data);
        console.log("USER_ID_BIS",this.form.utilisateur_id)
        this.fetchDemandes();
        this.resetForm();
        console.log("USER_ID_BIS_1",this.form.utilisateur_id)
        //this.fetchDemandes();
        this.showModal = false;
      } catch (error) {
        console.error('Failed to create Demande OM:', error);
      }
    },
    resetForm() {
      this.form = {
        participants: [],
        lieu_a_se_rendre: '',
        lieu_de_provenance: '',
        objet: '',
        date_de_depart: '',
        date_de_retour: '',
        transport_en_commun: false,
        //tdr:''
       // numero_vehicule: '',
        //nom_du_conducteur: '',
      };
      this.selectedFile = null;
      this.participantSearch = '';
      this.selectedInactiveParticipants = [];
      this.selectedActiveParticipants = [];
    },
    async fetchDemandes() {
      try {
        const response = await api.getDemandesByUser(this.form.utilisateur_id);
        this.demandes = response.data;
      } catch (error) {
        console.error('Error fetching demandes:', error);
      }
    },
    
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1; // Reverse order if sorting by the same key
      } else {
        this.sortKey = key;
        this.sortOrder = 1; // Default to ascending order
      }
    },
    formatDate(dateString) {
        //const options = { year: 'numeric', month: 'long', day: 'numeric' };
        //return new Date(dateString).toLocaleDateString(undefined, options);
        return new Date(dateString).toISOString().substr(0, 10); 
      },
    truncateText(text, maxLength) {
        if (text.length > maxLength) {
          return text.slice(0, maxLength) + '...';
        }
        return text;
      }
  }
};
</script>

<style scoped>
.modal {
  display: block; /* For displaying modal without bootstrap's JS */
}
th {
  cursor: pointer;
}
</style>
