<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";
//import SidenavCard from "./SidenavCard.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/dashboard-default"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          :navText="isRTL ? 'Tableau de bord' : 'Tableau de bord'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/demandomliste"
          :class="getRoute() === 'demandomliste' ? 'active' : ''"
          :navText="isRTL ? 'Gestion OM' : 'Gestion OM'"
        >
          <template v-slot:icon>
            <i class="fa fa-file-o text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/missions"
          :class="getRoute() === 'missions' ? 'active' : ''"
          :navText="isRTL ? 'Missions' : 'Missions'"
        >
          <template v-slot:icon>
           
            <i class="fa fa-tasks text-success text-sm opacity-10" aria-hidden="true"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/vehicules"
          :class="getRoute() === 'vehicules' ? 'active' : ''"
          :navText="isRTL ? 'Véhicules' : 'Véhicules'"
        >
          <template v-slot:icon>
            <i class="fa fa-car text-success text-sm opacity-10" aria-hidden="true"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/conducteurs"
          :class="getRoute() === 'conducteurs' ? 'active' : ''"
          :navText="isRTL ? 'CVAs' : 'CVAs'"
        >
          <template v-slot:icon>
            <i class="fa fa-male text-success text-sm opacity-10" aria-hidden="true"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/participants"
          :class="getRoute() === 'participants' ? 'active' : ''"
          :navText="isRTL ? 'Collaborateurs' : 'Collaborateurs'"
        >
          <template v-slot:icon>
            <i class="fa fa-user-o text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/demandomform"
          :class="getRoute() === 'demandomform' ? 'active' : ''"
          :navText="isRTL ? 'Demande OM' : 'Demande OM'"
        >
          <template v-slot:icon>
            <i class="fa fa-file-text-o text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <!--<li class="nav-item">
        <sidenav-item
          to="/billing"
          :class="getRoute() === 'billing' ? 'active' : ''"
          :navText="isRTL ? 'الفواتیر' : 'Billing'"
        >
          <template v-slot:icon>
            <i class="ni ni-credit-card text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/virtual-reality"
          :class="getRoute() === 'virtual-reality' ? 'active' : ''"
          :navText="isRTL ? 'الواقع الافتراضي' : 'Virtual Reality'"
        >
          <template v-slot:icon>
            <i class="ni ni-app text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/rtl-page"
          :class="getRoute() === 'rtl-page' ? 'active' : ''"
          navText="RTL"
        >
          <template v-slot:icon>
            <i class="ni ni-world-2 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>-->

      <li class="mt-3 nav-item">
        

        <h6
        
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          PAGES DE COMPTES
        </h6>
      </li>

      <!--<li class="nav-item">
        <sidenav-item
          to="/profile"
          :class="getRoute() === 'profile' ? 'active' : ''"
          :navText="isRTL ? 'Profil' : 'Profil'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>-->

      <li class="nav-item">
        <sidenav-item
          to="/login"
          :class="getRoute() === 'signin' ? 'active' : ''"
          :navText="isRTL ? 'Connexion' : 'Connexion'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-copy-04 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <!--<li class="nav-item">
        <sidenav-item
          to="/signup"
          :class="getRoute() === 'signup' ? 'active' : ''"
          :navText="isRTL ? 'اشتراك' : 'Sign Up'"
        >
          <template v-slot:icon>
            <i class="ni ni-collection text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>-->
    </ul>
  </div>

  <!--<div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :card="{
        title: 'Need Help?',
        description: 'Please check our docs',
        links: [
          {
            label: 'Documentation',
            route:
              'https://www.creative-tim.com/learning-lab/vue/overview/argon-dashboard/',
            color: 'dark',
          },
          {
            label: 'Buy now',
            route:
              'https://www.creative-tim.com/product/vue-argon-dashboard-pro?ref=vadp',
            color: 'success',
          },
        ],
      }"
    />
  </div>-->
</template>
