import axios from 'axios';
import store from '@/store';
import router from '@/router';

const apiClient = axios.create({
  baseURL: 'https://mission.dedras.org', 
  //baseURL: 'http://localhost:5000',// Changez cela pour l'URL de votre API
  withCredentials: true, // Cela peut être nécessaire selon votre configuration de CORS
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    //'Authorization': `Bearer ${localStorage.getItem('token')}`
  }
});

// Intercepteur pour ajouter le token d'autorisation
apiClient.interceptors.request.use(config => {
  const token = store.getters.getToken; 
  const user=store.getters.getUser; 
  console.log("TOKEN STOCKE");
  console.log(token);
  console.log(user);// Récupérer le token du store Vuex
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

 

// Intercepteur de réponses pour gérer les erreurs d'authentification
/*apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const response = await axios.post('https://api.example.com/refresh-token', {}, { withCredentials: true });
        store.commit('setToken', response.data.token);
        originalRequest.headers['Authorization'] = `Bearer ${response.data.token}`;
        return api(originalRequest);
      } catch (refreshError) {
        store.commit('logout');
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);*/

// Intercepteur de réponse pour gérer les erreurs d'authentification
apiClient.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Vérifier si c'est une erreur 401 et que ce n'est pas une nouvelle tentative
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Tenter de rafraîchir le token
        const response = await apiClient.post('/api/refresh-token', {}, { withCredentials: true });

        const newToken = response.data.accessToken;

        // Mettre à jour le token dans le store
        console.log("SET REFRESH NEW")
        store.commit('SET_TOKEN', newToken);

        // Mettre à jour les en-têtes pour toutes les requêtes futures
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

        // Mettre à jour les en-têtes pour cette requête particulière
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

        // Réessayer la requête initiale
        return apiClient(originalRequest);
      } catch (refreshError) {
        // Si le rafraîchissement échoue (refresh token expiré, par exemple)
        store.commit('CLEAR_USER'); // Supprimer les informations de l'utilisateur
        router.push('/login'); // Rediriger vers la page de connexion
        return Promise.reject(refreshError);
      }
    }

    // Si ce n'est pas une erreur 401 ou si la tentative de refresh a échoué
    return Promise.reject(error);
  }
);

export default {
  register(user) {
    return apiClient.post('/api/register', user);
  },
  login(user) {
    console.log("LOGINN");
    return apiClient.post('/api/login', user);

  },

  tracker(data) {
    console.log("store location");
    return apiClient.post('/api/location', data);
  },
  map(conducteur_id, mission_id) {
    console.log("get latest position");
    return apiClient.get(`/api/location/latest/${conducteur_id}/${mission_id}`);

  },
  verifyEmail(token) {
    return apiClient.get(`/api/verify-email?token=${token}`);
  },
  forgotPassword(email) {
    return apiClient.post('/api/forgot-password', { email });
  },
  resetPassword(data) {
    return apiClient.post('/api/reset-password', data);
  },
  // Ajoutez d'autres méthodes pour accéder à votre API ici
  sendLocation(data){
    return apiClient.post('/api/location', data);
  },

  
  //API Conducteur
  getDrivers() {
    return apiClient.get('/api/conducteurs');
  },

  // Récupérer un conducteur par ID
  getDriver(id) {
    return apiClient.get(`/api/conducteurs/${id}`);
  },

  // Ajouter un nouveau conducteur
  addDriver(driverData) {
    return apiClient.post('/api/conducteurs', driverData);
  },

  // Mettre à jour un conducteur existant
  updateDriver(id, driverData) {
    return apiClient.put(`/api/conducteurs/${id}`, driverData);
  },

  // Supprimer un conducteur
  deleteDriver(id) {
    return apiClient.delete(`/api/conducteurs/${id}`);
  },
  getUsers() {
    return apiClient.get('/api/utilisateurs');
  },

  // API Vehicule

  getVehicles() {
    return apiClient.get('/api/vehicules');
  },
  // Récupérer un conducteur par ID
  getVehicule(id) {
    return apiClient.get(`/api/vehicules/${id}`);
  },
  addVehicle(vehicle) {
    return apiClient.post('/api/vehicules', vehicle);
  },
  updateVehicle(id, vehicle) {
    return apiClient.put(`/api/vehicules/${id}`, vehicle);
  },
  deleteVehicle(id) {
    return apiClient.delete(`/api/vehicules/${id}`);
  },

  //API Missions
  getMissions() {
    return apiClient.get('/api/missions');
  },
  getMission(id) {
    return apiClient.get(`/api/missions/${id}`);
  },
  addMission(formData) {
    return apiClient.post('/api/missions', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  updateMission(id, formData) {
    return apiClient.put(`/api/missions/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  deleteMission(id) {
    return apiClient.delete(`/api/missions/${id}`);
  },
  /*async getVehicules() {
    return await axios.get('/api/vehicules');
  },
  async getConducteurs() {
    return await axios.get('/api/conducteurs');
  }*/
  //API Pour le chauffeur

  getConducteurMissions(conducteurId) {
    return apiClient.get(`/api/missions/conducteur/${conducteurId}`);
  },
  getConducteurAllMissions(conducteurId) {
    return apiClient.get(`/api/allmissions/conducteur/${conducteurId}`);
  },
  updateHeureDepart(rapportId,data) {
    return apiClient.put(`/api/mission-reports/departure/${rapportId}`,data);
  },
  updateHeureArrivee(rapportId,data) {
    return apiClient.put(`/api/mission-reports/arrival/${rapportId}`,data);
  },
  getMissionReports(missionId) {
    return apiClient.get(`/api/mission-reports/mission/${missionId}`);
  },


  getMissionPositions(missionId) {
    return apiClient.get(`/api/missions/positions/${missionId}`);
  },
  getStatistics(period) {
    /*return apiClient.get('/api/missions/statistics', {
      params: { period },
    });*/
    return apiClient.get(`/api/missions/statistics/${period}`, )
  },
  getStatisticsByDateRange(startDate,endDate) {
    /*return apiClient.get('/api/missions/statistics', {
      params: { period },
    });*/
    return apiClient.get(`/api/missions/statisticsbydaterange/${startDate}/${endDate}`, )
  },


  getTotalMissions() {
    return apiClient.get('/api/stats/missions/total');
  },
  getTotalVehicles() {
    return apiClient.get('/api/stats/vehicules/total');
  },
  getTotalConducteurs() {
    return apiClient.get('/api/stats/conducteurs/total');
  },
  getGlobalAverages() {
    return apiClient.get('/api/stats/statistics/averages');
  },



  //API PARTICIPANTS
getParticipants() {
    return apiClient.get('/api/participants');
  },
 addParticipant(participant) {
    return apiClient.post('/api/participants', participant);
  },
updateParticipant(id, participant) {
    return apiClient.put(`/api/participants/${id}`, participant);
  },
deleteParticipant(id) {
    return apiClient.delete(`/api/participants/${id}`);
  },

  //API DEMANDE OM
// Récupérer toutes les Demande_oms
getDemandeOms() {
  return apiClient.get('/api/alldemandeoms');
},

getDemandeOmsValide() {
  return apiClient.get('/api/demande_oms/inmission');
},

// Récupérer une Demande_om par ID
getDemandeOm(id) {
  return apiClient.get(`/api/demande_oms/${id}`);
},

// Créer une nouvelle Demande_om
createDemandeOm(data) {
  return apiClient.post('/api/demande_oms', data,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
},

// Mettre à jour une Demande_om par ID
updateDemandeOm(id, data) {
  return apiClient.put(`/api/demande_oms/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
},

// Supprimer une Demande_om par ID
deleteDemandeOm(id) {
  return apiClient.delete(`/api/demande_oms/${id}`);
},

// Valider une Demande_om (mettre is_sd_validate à true)
validateDemandeOm(id) {
  return apiClient.patch(`/api/demande_oms/${id}/validate`, { is_sd_validate: true });
},

// Récupérer les OMs liés à une Demande_om
getOmsByDemandeOm(id) {
  return apiClient.get(`/api/demande_oms/${id}/oms`);
},

getOmPdf(omId) {
  return apiClient.get(`api/oms/${omId}/pdf`, { responseType: 'blob' });
},

generateSingleOm(demandeOmId) {
  return apiClient.post(`/api/oms/generateSingle`, { demandeOmId });
},
generateMultipleOms(demandeOmId) {
  return apiClient.post(`/api/oms/generateMultiple`, { demandeOmId });
},
getOmsForDemandeOm(demandeOmId) {
  return apiClient.get(`/api/oms/demandeOm/${demandeOmId}`);
},
getDemandesByUser(userId) {
  return apiClient.get(`/api/demande_oms/utilisateur/${userId}`);
},

refreshtoken() {
  console.log("SET REFRESH NEW FUNC")
  return apiClient.post('/api/refresh-token', {}, { withCredentials: true })
    .then(response => {
      const newToken = response.data.token;
      console.log("NWTOK",newToken)
      store.commit('SET_TOKEN', newToken);

      // Update headers for future requests
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

      return newToken;
    })
    .catch(error => {
      console.error("Failed to refresh token", error);
      throw error;
    });
}

}