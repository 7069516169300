<template>
  <div>
    <h2 class="text-xl font-weight-bold mb-0 text-center" style="background-color: wheat;">DETAILS DE LA DEMANDE D'OM</h2>
    <div class="row" v-if="demandeOm">
      <div class="col-md-6" style="background-color: wheat;">
        <h2 class="text-lg font-weight-bold mb-0 text-center" style="color:navy">TDR</h2>
        <iframe v-if="tdrUrl" :src="tdrUrl" width="100%" height="600px"></iframe>
      </div>
      <div class="col-md-6" style="background-color: wheat;">
        <h2 class="text-lg font-weight-bold mb-0 text-center" style="color:navy">INFORMATIONS</h2>
        <table class="table">
          <tbody>
            <!--<tr>
              <td><strong>Réf:</strong></td>
              <td>{{ demandeOm.id }} N°               /24/DEDRAS/CN/DAFC/CARI/SD</td>
            </tr>-->
            <tr>
              <td><strong>Aux nommé(es):</strong></td>
              <td>
                <ul>
                  <li v-for="participant in demandeOm.participants" :key="participant.id">
                    {{ participant.noms }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td><strong>Fonction(s):</strong></td>
              <td>{{ demandeOm.fonctions }}</td>
            </tr>
            <tr>
              <td><strong>De se rendre à :</strong></td>
              <td>{{ demandeOm.lieu_a_se_rendre }}</td>
            </tr>
            <tr>
              <td><strong>Lieu de provenance:</strong></td>
              <td>{{ demandeOm.lieu_de_provenance }}</td>
            </tr>
            <tr>
              <td><strong>Pour (Objet complet):</strong></td>
              <td>{{ demandeOm.objet }}</td>
            </tr>
            <tr>
              <td><strong>Date de départ:</strong></td>
              <td>{{ formatDate(demandeOm.date_de_depart) }}</td>
            </tr>
            <tr>
              <td><strong>Date de retour:</strong></td>
              <td>{{ formatDate(demandeOm.date_de_retour) }}</td>
            </tr>
            <tr v-if="isTransportEnco(demandeOm)">
              <td><strong>Type de véhicule:</strong></td>
              <td>Transport en commun</td>
            </tr>
          </tbody>
        </table>

        <button @click="validateDemandeOm" class="btn btn-success">Valider</button>
        <button @click="goBack" class="btn btn-secondary">Retour</button>
      </div>
    </div>
    <div v-else>
      <LoadingModal :showModal="isLoading" />
    </div>
    <LoadingModal :showModal="isLoading" />
  </div>
</template>

<script>
import api from '@/services/api';
import LoadingModal from '@/views/components/LoadingModal.vue';

export default {
  name: 'DemandeOmDetail',
  components: {
    LoadingModal
  },
  data() {
    return {
      demandeOm: null,
      isLoading: false 
    };
  },
  computed: {
    tdrUrl() {
      //return this.demandeOm ? `http://localhost:5000/public/mission_orders/${this.demandeOm.tdr}` : '';
      return this.demandeOm ? `https://api.dedras.org/public/mission_orders/${this.demandeOm.tdr}` : '';
    }
  },
  async created() {
    await this.fetchDemandeOm();
  },
  methods: {
    async fetchDemandeOm() {
      const id = this.$route.params.id;
      try {
        const response = await api.getDemandeOm(id);
        this.demandeOm = response.data;
      } catch (error) {
        console.error('Failed to fetch demande_om:', error);
      }
    },

    async validateDemandeOm() {
      this.isLoading = true; // Show the loading modal
      try {
        await api.validateDemandeOm(this.demandeOm.id);
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push({ name: 'ListeDemandeOms' });
        }, 5000); 
      } catch (error) {
        this.isLoading = false; // Hide the loading modal in case of error
        console.error('Failed to validate demande_om:', error);
      }
    },
    isTransportEnco(demande) {
      return demande.transport_en_commun;
    },
    goBack() {
      this.$router.push({ name: 'ListeDemandeOms' });
    },
    formatDate(dateString) {
        //const options = { year: 'numeric', month: 'long', day: 'numeric' };
        //return new Date(dateString).toLocaleDateString(undefined, options);
        return new Date(dateString).toISOString().substr(0, 10); 
      },
  }
};
</script>
