import { createStore } from "vuex";
import VuexPersist from 'vuex-persist';
import api from '@/services/api';
import router from '@/router';

const vuexPersist = new VuexPersist({
  key: 'mdd_argon',
  storage: window.localStorage, // Utilise le localStorage pour persister l'état
  reducer: (state) => ({ user: state.user, token: state.token }) // Persiste uniquement user et token
});

export default createStore({
  state: {
    user: null,
    isAuthenticated: false,
    refreshInterval: null,
    token: null,
    hideConfigButton: false,
    isPinned: false,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default"
    // autres états...
  },
  mutations: {
    SET_USER(state, user) {
      console.log('SET_USER mutation', user);
      state.user = user;
      state.isAuthenticated = !!user;
    },
    SET_TOKEN(state, token) {
      console.log('SET_TOKEN mutation', token);
      state.token = token;
    },
    CLEAR_USER(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.token = null;
      clearInterval(state.refreshInterval);
      state.refreshInterval = null;
    },
    SET_REFRESH_INTERVAL(state, interval) {
      state.refreshInterval = interval;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    sidebarMinimize(state) {
      let sidenav_show = document.querySelector("#app");
      if (state.isPinned) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    // autres mutations...
  },
  actions: {
    async login({ commit, dispatch }, credentials) {
      try {
        const response = await api.login(credentials);
        if (response.data && response.data.token && response.data.user) {
          commit('SET_USER', response.data.user);
          commit('SET_TOKEN', response.data.token);
          
          dispatch('scheduleTokenRefresh');
          if (response.data.user.role_id == 1) {
            router.push('/dashboard-default');
          } else if (response.data.user.role_id == 4) {
            router.push('/dashboard-driver');
          } else if (response.data.user.role_id == 3) {
            router.push('/demandomform');
          }else if (response.data.user.role_id == 2) {
            router.push('/demandomliste');
          }else {
            router.push('/non-access');
          }
        } else {
          throw new Error('Invalid response data');
        }
      } catch (error) {
        console.error('Login error:', error);
        return error;
      }
    },
    async logout({ commit }) {
      try {
        await api.post('/logout');
        commit('CLEAR_USER');
      } catch (error) {
        console.error('Logout error:', error);
      }
    },
    async checkAuth({ commit, dispatch }) {
      try {
        const response = await api.get('/me');
        commit('SET_USER', response.data.user);
        dispatch('scheduleTokenRefresh');
      } catch (error) {
        commit('CLEAR_USER');
      }
    },
    scheduleTokenRefresh({ commit, dispatch }) {
      console.log("init shedule token refresh in store")
      const interval = setInterval(() => {
        dispatch('refreshToken');
      }, 30 * 60 * 1000); // Rafraîchir toutes les 14 minutes
      commit('SET_REFRESH_INTERVAL', interval);
    },
    async refreshToken({ commit }) {
      try {
        console.log("init refresh token in store")
        await api.refreshtoken();
        //commit('SET_TOKEN', response.data.token);
      } catch (error) {
        console.error('Failed to refresh token:', error);
        commit('CLEAR_USER');
        router.push('/login');  // Rediriger l'utilisateur
      }
    },
    // autres actions...
  },
  getters: {
    isAuthenticated: state => !!state.token,
    getUser: state => state.user,
    getToken: state => state.token,
    // autres getters...
  },
  plugins: [vuexPersist.plugin]
});
