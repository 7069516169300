<template>
    <div class="container">
      <h2>Choisir une période pour les statistiques des missions</h2>
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="startDate" class="form-label">Date de début</label>
          <input
            type="date"
            id="startDate"
            v-model="startDate"
            class="form-control"
            required
          />
        </div>
        <div class="mb-3">
          <label for="endDate" class="form-label">Date de fin</label>
          <input
            type="date"
            id="endDate"
            v-model="endDate"
            class="form-control"
            required
          />
        </div>
        <button type="submit" class="btn btn-primary">Afficher les statistiques</button>
      </form>
  
      <!-- Afficher les statistiques -->
      <div v-if="statistics">
        <h3>Statistiques des missions du {{ startDate }} au {{ endDate }}</h3>
        
        <canvas id="myChart" ref="chart1" width="400" height="200"></canvas>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, nextTick } from 'vue';
  import api from '@/services/api';
  import { Chart, registerables } from 'chart.js';
  Chart.register(...registerables);
  
  export default {
    name: "StatisticsByDateRange",
    setup() {
      const startDate = ref('');
      const endDate = ref('');
      const statistics = ref(null);
      const chart1 = ref(null);
      const chartInstance1 = ref(null);
  
      const submitForm = async () => {
        try {
          const response = await api.getStatisticsByDateRange(startDate.value, endDate.value);
          statistics.value = response.data.statistics;
          console.log("STATS RANGE VALUE", statistics.value);
          await nextTick();
          renderChart();
         
        } catch (error) {
          console.error("Erreur lors de la récupération des statistiques :", error);
        }
      };
  
      const renderChart = () => {
  if (!statistics.value || Object.keys(statistics.value).length === 0) {
    console.warn("Pas de données disponibles pour les statistiques.");
    return;
  }

  if (chartInstance1.value) {
    chartInstance1.value.destroy();
  }

  if (chart1.value) {
    const ctx = chart1.value.getContext('2d');
    chartInstance1.value = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: Object.keys(statistics.value),
        datasets: [
          {
            label: 'Nombre de jours de Missions',
            data: Object.values(statistics.value),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }
};
  
  
      return {
        startDate,
        endDate,
        statistics,
        submitForm,
        chart1,
        chartInstance1
      };
    },
  };
  </script>