<template>
  <div v-if="isAuthenticated">
    <h1 class="page-title">MES MISSIONS</h1>
      <button class="btn btn-primary" @click="driverhistoric">HISTORIQUE</button>
     
      <div class="table-responsive p-0">

      <table class="table align-items-center mb-0">
      <thead>
        <tr>
         <!-- <th>ID</th>-->
          <!--<th>Nom</th>-->
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-10">Missions</th>


        </tr>
      </thead>
      <tbody>
        <tr v-for="mission in missions" :key="mission.id">

         <!-- <td>{{ mission.id }}</td>-->
           
          <!--<td>{{ mission.nom }}</td>-->
          <td class="text-xs font-weight-bold mb-0">
              <strong>Objet :</strong>
              <ul><li> {{ mission.objet }}</li></ul>
              <strong>Lieu :</strong>
              <ul><li> {{ mission.lieu }}</li></ul>
              <strong>Date début :</strong>
              <ul><li> {{ formatDate(mission.date_debut_prevue) }}</li></ul>
              <strong>Date fin :</strong> 
              <ul><li>{{ formatDate(mission.date_fin_prevue) }}</li></ul>
              <strong>Statut :</strong>
              <ul><li><span v-if="getMissionStatus(mission) === 'A venir'" class="badge badge-sm bg-gradient-secondary">A venir</span>
          <span v-if="getMissionStatus(mission) === 'Terminée'"  class="badge badge-sm bg-gradient-danger">Terminée</span>
          <span v-if="getMissionStatus(mission) === 'En cours'" class="badge badge-sm bg-gradient-success">En cours</span></li></ul>
              <strong>Éditer Départ :</strong>
              <ul><li> <button v-if="getStatus(mission) === 'Active'" @click="openDepartModal(mission.Missionreports[0])" class="btn btn-primary me-2">Éditer Départ</button></li></ul>
              <strong>Éditer arrivée :</strong>
              <ul><li> <button v-if="getStatus(mission) === 'Active'" @click="openArriveeModal(mission.Missionreports[0])" class="btn btn-secondary">Éditer Arrivée</button></li></ul>
              
          </td>
         
        </tr>
      </tbody>
    </table>
    </div>

    <!-- Modal pour éditer l'heure de départ -->
    <div class="modal fade" id="editDepartModal" tabindex="-1" aria-labelledby="editDepartModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editDepartModalLabel">Éditer Heure de Départ</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateHeureDepart">
              <div class="mb-3">
                <label for="heure_depart" class="form-label">Heure de départ</label>
                <input type="time" id="heure_depart" v-model="rapport.heure_depart" class="form-control">
              </div>
              <button type="submit" class="btn btn-primary">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal pour éditer l'heure d'arrivée -->
    <div class="modal fade" id="editArriveeModal" tabindex="-1" aria-labelledby="editArriveeModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editArriveeModalLabel">Éditer Heure d'Arrivée</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateHeureArrivee">
              <div class="mb-3">
                <label for="heure_arrivee" class="form-label">Heure d'arrivée</label>
                <input type="time" id="heure_arrivee" v-model="rapport.heure_arrivee" class="form-control">
              </div>
              <button type="submit" class="btn btn-primary">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import { Modal } from 'bootstrap';

export default {
  name: 'ConducteurMissions',
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  data() {
    return {
      missions: [],
      rapport: {
        heure_depart: '',
        heure_arrivee: '',
        latitude: '',
        longitude: ''
      },
      currentRapport: null,
    };
  },
  async mounted() {
    await this.fetchMissions();
  },
  methods: {
    async fetchMissions() {
      try {
        //const response = await api.getConducteurMissions(this.user.id);
        const response = await api.getConducteurMissions(this.user.id);
        this.missions = response.data;
        console.log("MISSIONS", this.missions)
      } catch (error) {
        console.error('Erreur lors de la récupération des missions:', error);
      }
    },
    getStatus(mission) {
      const today = new Date();
      const dateDebut = new Date(mission.date_debut_prevue);
      const dateFin = new Date(mission.date_fin_prevue);
      return (today >= dateDebut && today <= dateFin) ? 'Active' : 'Inactive';

      
    },
    getMissionStatus(mission) {
        const now = new Date();
        const start = new Date(mission.date_debut_prevue);
        const end = new Date(mission.date_fin_prevue);
  
        if (now < start) {
          return 'A venir';
        } else if (now > end) {
          return 'Terminée';
        } else {
          return 'En cours';
        }
      },
    openDepartModal(rapport) {
      this.currentRapport = rapport;
      this.rapport.heure_depart = '';
      const editDepartModal = new Modal(document.getElementById('editDepartModal'));
      editDepartModal.show();
    },
    openArriveeModal(rapport) {
      this.currentRapport = rapport;
      this.rapport.heure_arrivee = '';
      const editArriveeModal = new Modal(document.getElementById('editArriveeModal'));
      editArriveeModal.show();
    },
    async updateHeureDepart() {
      try {
        const position = await this.getCurrentPosition();
        console.log("CUREENT RAPPORT",this.currentRapport)
        console.log("Position",position)
        await api.updateHeureDepart(this.currentRapport.id, {
          heure_depart: this.rapport.heure_depart,
          latitude: position.latitude,
          longitude: position.longitude
        });
        const editDepartModal = Modal.getInstance(document.getElementById('editDepartModal'));
        editDepartModal.hide();
      } catch (error) {
        console.error('Erreur lors de la mise à jour de l\'heure de départ:', error);
      }
    },
    async updateHeureArrivee() {
      try {
        const position = await this.getCurrentPosition();
        await api.updateHeureArrivee(this.currentRapport.id, {
          heure_arrivee: this.rapport.heure_arrivee,
          latitude: position.latitude,
          longitude: position.longitude
        });
        const editArriveeModal = Modal.getInstance(document.getElementById('editArriveeModal'));
        editArriveeModal.hide();
      } catch (error) {
        console.error('Erreur lors de la mise à jour de l\'heure d\'arrivée:', error);
      }
    },
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    driverhistoric() {
      this.$router.push({ name: 'Driver_Historic' });
    },
  }
};
</script>