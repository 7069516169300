<template>

    <!-- Affichage des missions -->
    <div v-if="isAuthenticated">
      <h1 class="page-title">GESTION DES MISSIONS</h1>
      <button class="btn btn-primary" @click="showAddModal"><i class="fa fa-plus">  </i>Ajouter une mission</button>
      <input type="text" v-model="searchQuery" placeholder="Rechercher..." class="form-control my-3">
      <div class="table-responsive p-0">

      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-10" @click="sortTable('objet')">Objet</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortTable('nombre_de_jours')">Nbre de jrs</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortTable('localisation_geographique')">Local. géo</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortTable('date_debut_prevue')">Date début prévue</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortTable('date_fin_prevue')">Date fin prévue</th>
            <th  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortTable('vehicule_id')">Véhicule</th>
            <th  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortTable('conducteur_id')">Conducteur</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9">Statut</th>
        <!--<th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Actions</th>-->
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="mission in filteredMissions" :key="mission.id">
            <td> <p class="text-xs text-secondary mb-0">{{truncateText(mission.objet, 50) }}</p></td>
        <td class="text-xs font-weight-bold mb-0 text-center">{{ mission.nombre_de_jours }}</td>
        <td class="text-xs font-weight-bold mb-0 text-center">{{ mission.localisation_geographique }}</td>
        <td class="align-middle text-center"><span class="text-secondary text-xs font-weight-bold">{{ formatDate(mission.date_debut_prevue) }}</span></td>
        <td class="align-middle text-center"><span class="text-secondary text-xs font-weight-bold">{{ formatDate(mission.date_fin_prevue) }}</span></td>
        <td class="text-xs font-weight-bold mb-0 text-center">
          <div class="d-flex flex-column justify-content-center mr-3">
            {{ getVehiculeInfo1(mission.vehicule_id, 'immatriculation') }}
              <p class="text-xs text-secondary mb-0">{{ getVehiculeInfo1(mission.vehicule_id, 'marque') }} - {{ getVehiculeInfo1(mission.vehicule_id, 'modele') }}</p>
           </div></td>
        <td class="text-xs font-weight-bold mb-0 text-center">{{ getConducteurInfo(mission.conducteur_id, 'nom') }}</td>
            <td class="align-middle text-center text-sm">
          <span v-if="getMissionStatus(mission) === 'A venir'" class="badge badge-sm bg-gradient-secondary">A venir</span>
          <span v-if="getMissionStatus(mission) === 'Terminée'"  class="badge badge-sm bg-gradient-danger">Terminée</span>
          <span v-if="getMissionStatus(mission) === 'En cours'" class="badge badge-sm bg-gradient-success">En cours</span>
        
        
        </td>
        <td>
          <div class="d-flex flex-column justify-content-center mr-3">
              <button @click="showEditModal(mission)" class="btn btn-warning"><i class="fas fa-edit"></i></button>
              <p class="text-xs text-secondary mb-0">Edit.</p>
           </div>
        </td>
        <td>
          <div class="d-flex flex-column justify-content-center mr-3">
          <button @click="showDeleteModal(mission)" class="btn btn-danger"><i class="fas fa-trash-alt"></i></button>
           <p class="text-xs text-secondary mb-0">Suppr.</p>
           </div>
        </td>
        <td>
            <div class="d-flex flex-column justify-content-center mr-3">
            <button @click="viewMissionReports(mission.id)" class="btn btn-info"><i class="fas fa-file-alt"></i></button>
            <p class="text-xs text-secondary mb-0">Rappr.</p>
            </div>
  
        </td>
        <td>
          <div class="d-flex flex-column justify-content-center mr-3">
            <button @click="viewMissionPositions(mission.id)" class="btn btn-secondary"><i class="fas fa-map-marker-alt"></i></button>
            <p class="text-xs text-secondary mb-0">Local.</p>
           </div>
        </td>
        <td>
          <div class="d-flex flex-column justify-content-center">
        <button @click="showDetailsModal(mission)" class="btn btn-primary"><i class="fas fa-info-circle"></i></button>
        <p class="text-xs text-secondary mb-0">Détai.</p>
      </div>
  
        </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal pour ajouter une mission -->
    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalLabel">Ajouter Mission</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addMission">
              <div class="mb-3">
                <label for="demande_om" class="form-label">Demande OM</label>
            <select v-model="newMission.demande_om_id" class="form-select">
              <option v-for="demande in demandes_om" :key="demande.id" :value="demande.id">
                {{ demande.objet }} - {{ demande.lieu_a_se_rendre }}
              </option>
            </select>
            <div v-if="selectedDemande" class="demandom">
              <strong>Aux nommé(es):</strong>
              <ul>
                <li v-for="participant in selectedDemande.participants" :key="participant.id">
                  {{ participant.noms }}
                </li>
              </ul>
              <strong>Fonctions :</strong>
              <ul><li> {{ selectedDemande.fonctions }}</li></ul>
              <strong>Pour se rendre à :</strong>
              <ul><li> {{ selectedDemande.lieu_a_se_rendre }}</li></ul>
              <strong>En provenance de :</strong>
              <ul><li> {{ selectedDemande.lieu_de_provenance }}</li></ul>
              <strong>Pour :</strong> 
              <ul><li>{{ selectedDemande.objet }}</li></ul>
              <strong>Date de départ :</strong>
              <ul><li> {{ formatDate(selectedDemande.date_de_depart) }}</li></ul>
              <strong>Date de retour :</strong>
              <ul><li> {{ formatDate(selectedDemande.date_de_retour) }}</li></ul>
              
              
            </div>
              </div>
              <div class="mb-3">
                <label for="vehicule" class="form-label">Véhicule</label>
                <select v-model="newMission.vehicule_id" class="form-select">
                  <option v-for="vehicule in vehicules" :key="vehicule.id" :value="vehicule.id">
                    {{ vehicule.marque }} - {{ vehicule.modele }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="conducteur" class="form-label">Conducteur</label>
                <select v-model="newMission.conducteur_id" class="form-select">
                  <option v-for="conducteur in conducteurs" :key="conducteur.id" :value="conducteur.id">
                    {{ conducteur.utilisateur.nom }}
                  </option>
                </select>
              </div>
              <!--<div class="mb-3">
                <label for="ordre_de_mission" class="form-label">Ordre de mission</label>
                <input type="file" id="addOrdre_de_mission" @change="handleFileUpload" class="form-control">
              </div>-->
              <button type="submit" class="btn btn-primary">Ajouter</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal pour modifier une mission -->
    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">Modifier Mission</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateMission">
              <div class="mb-3">
                <label for="editDemande_om" class="form-label">Demande OM</label>
                <select v-model="currentMission.demande_om_id" class="form-select">
                  <option v-for="demande in demandes_om" :key="demande.id" :value="demande.id">
                    {{demande.objet}}
                  </option>
                </select>
                <div v-if="selectedDemande1" class="demandom">
                  <strong>Aux nommé(es) :</strong>
              <ul>
                <li v-for="participant in selectedDemande1.participants" :key="participant.id">
                  {{ participant.noms }}
                </li>
              </ul>
              <strong>Fonctions :</strong>
              <ul><li> {{ selectedDemande1.fonctions }}</li></ul>
              <strong>Pour se rendre à :</strong>
              <ul><li> {{ selectedDemande1.lieu_a_se_rendre }}</li></ul>
              <strong>En provenance de :</strong>
              <ul><li> {{ selectedDemande1.lieu_de_provenance }}</li></ul>
              <strong>Pour :</strong> 
              <ul><li>{{ selectedDemande1.objet }}</li></ul>
              <strong>Date de départ :</strong>
              <ul><li> {{ formatDate(selectedDemande1.date_de_depart) }}</li></ul>
              <strong>Date de retour :</strong>
              <ul><li> {{ formatDate(selectedDemande1.date_de_retour) }}</li></ul>
              
              
            </div>
              </div>
              <div class="mb-3">
                <label for="editVehicule" class="form-label">Véhicule</label>
                <select v-model="currentMission.vehicule_id" class="form-select">
                  <option v-for="vehicule in vehicules" :key="vehicule.id" :value="vehicule.id">
                    {{ vehicule.marque }} - {{ vehicule.modele }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="editConducteur" class="form-label">Conducteur</label>
                <select v-model="currentMission.conducteur_id" class="form-select">
                  <option v-for="conducteur in conducteurs" :key="conducteur.id" :value="conducteur.id">
                    {{ conducteur.utilisateur.nom }}
                  </option>
                </select>
              </div>
              <!--<div class="mb-3">
                <label for="editOrdre_de_mission" class="form-label">Ordre de mission</label>
                <input type="file" id="editOrdre_de_mission" @change="handleFileUpload" class="form-control">
              </div>-->
              <button type="submit" class="btn btn-primary">Mettre à jour</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal pour supprimer une mission -->
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Supprimer Mission</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Êtes-vous sûr de vouloir supprimer cette mission ?</p>
            <button @click="deleteMission" class="btn btn-danger">Supprimer</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal pour les détails d'une mission -->
    <div class="modal fade" id="detailsModal" tabindex="-1" aria-labelledby="detailsModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="detailsModalLabel">Détails de la Mission</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <h5>Demande OM: {{ getDemandeOMInfo(currentMission.demande_om_id, 'nom') }}</h5>
            <p>Véhicule: {{ getVehiculeInfo(currentMission.vehicule_id, 'marque') }} - {{ getVehiculeInfo(currentMission.vehicule_id, 'modele') }} - {{ getVehiculeInfo(currentMission.vehicule_id, 'immatriculation') }}</p>
            <p>Conducteur: {{ getConducteurInfo(currentMission.conducteur_id, 'nom') }}</p>
            <p>Date de début: {{ currentMission.date_debut }}</p>
            <p>Date de fin: {{ currentMission.date_fin }}</p>
            <p>Lieu de départ: {{ currentMission.lieu_depart }}</p>
            <p>Lieu d'arrivée: {{ currentMission.lieu_arrivee }}</p>
            <p>Ordre de mission: <a :href="ordreDeMissionUrl" target="_blank">Voir le fichier</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'; // Assurez-vous que le chemin est correct
import { Modal } from 'bootstrap';
  export default {
    name: 'MissionsList',
  data() {
    return {
      missions: [],
      searchQuery: '',
      sortColumn: '',
      sortOrder: 'asc',
      //filteredMissions: [],
      vehicules: [],
      conducteurs: [],
      demandes_om: [],
      
      newMission: {
        demande_om_id: null,
        vehicule_id: null,
        conducteur_id: null,
        date_debut: '',
        date_fin: '',
        lieu_depart: '',
        lieu_arrivee: '',
      },
      currentMission: {
        demande_om_id: null,
        vehicule_id: null,
        conducteur_id: null,
        date_debut: '',
        date_fin: '',
        lieu_depart: '',
        lieu_arrivee: '',


      },
      ordreDeMissionUrl: '',
    };
  },
  computed: {
      isAuthenticated() {
          return this.$store.getters.isAuthenticated;
        },
        user() {
          return this.$store.getters.getUser;
        },
        token() {
          return this.$store.getters.getToken;
        },
        selectedDemande() {
      return this.demandes_om.find(demande => demande.id === this.newMission.demande_om_id);
    },selectedDemande1() {
      return this.demandes_om.find(demande => demande.id === this.currentMission.demande_om_id);
    },
    filteredMissions() {
    let filtered = this.missions.filter(mission => {
      const searchQueryLower = this.searchQuery.toLowerCase();
      
      return (
        (mission.nom && mission.nom.toLowerCase().includes(searchQueryLower)) ||
        (mission.objet && mission.objet.toLowerCase().includes(searchQueryLower)) ||
        (mission.lieu && mission.lieu.toLowerCase().includes(searchQueryLower)) ||
        (mission.localisation_geographique && mission.localisation_geographique.toLowerCase().includes(searchQueryLower))
      );
    });

    if (this.sortColumn) {
      filtered = filtered.sort((a, b) => {
        const aValue = a[this.sortColumn];
        const bValue = b[this.sortColumn];

        if (aValue < bValue) {
          return this.sortOrder === 'asc' ? -1 : 1;
        } else if (aValue > bValue) {
          return this.sortOrder === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    }

    return filtered;
  }
    
      /*filteredMissions() {
        let filtered = this.missions.filter(mission => {
          return (
            mission.nom.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            mission.objet.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        });
  
        if (this.sortColumn) {
          filtered = filtered.sort((a, b) => {
            const aValue = a[this.sortColumn];
            const bValue = b[this.sortColumn];
  
            if (aValue < bValue) {
              return this.sortOrder === 'asc' ? -1 : 1;
            } else if (aValue > bValue) {
              return this.sortOrder === 'asc' ? 1 : -1;
            } else {
              return 0;
            }
          });
        }
  
        return filtered;
      }*/
    },
  methods: {
    async fetchMissions() {
      try {
        const response = await api.getMissions();
        this.missions = response.data;
        console.log(this.missions);
        //this.filteredMissions = this.missions;
      } catch (error) {
        console.error('Erreur lors de la récupération des missions:', error);
      }
    },
    async fetchVehicules() {
      try {
        //const response = await api.getVehicules();
        console.log("fetch vehicles start");
        const response = await api.getVehicles();
        this.vehicules = response.data;
        console.log("fetch vehicles start",this.vehicules);
      } catch (error) {
        console.error('Erreur lors de la récupération des véhicules:', error);
      }
    },
    async fetchConducteurs() {
      try {
        const response = await api.getDrivers();
        this.conducteurs = response.data;
        console.log(this.conducteurs);
      } catch (error) {
        console.error('Erreur lors de la récupération des conducteurs:', error);
      }
    },
    /*async fetchUsers() {
          try {
            const response = await api.getUsers();
            this.users = response.data;
          } catch (error) {
            console.error('Failed to fetch users:', error);
          }
        },*/
      showAddModal() {
        const addModal = new Modal(document.getElementById('addModal'));
        addModal.show();
      },
      showEditModal(mission) {
        this.currentMission = { ...mission };
        const editModal = new Modal(document.getElementById('editModal'));
        editModal.show();
      },
      showDeleteModal(mission) {
        this.currentMission = { ...mission };
        const deleteModal = new Modal(document.getElementById('deleteModal'));
        deleteModal.show();
      },
      async showDetailsModal(mission) {
        console.log("Mission:", mission)
        this.currentMission = { ...mission };
        const detailsModal = new Modal(document.getElementById('detailsModal'));
        detailsModal.show();
      },
    async fetchDemandesOM() {
      try {
        const response = await api.getDemandeOmsValide();
        this.demandes_om = response.data;
        console.log("DEMANDE OM",this.demandes_om)
      } catch (error) {
        console.error('Erreur lors de la récupération des demandes OM:', error);
      }
    },
    async addMission() {
      try {
        const response = await api.addMission(this.newMission);
        //this.fetchVehicules();
       // this.fetchConducteurs();
       console.log("ADD MISSION RESPONSE",response.data)

        this.missions.push(response.data);
        
        console.log("ADD THIS.MISSIONS",this.missions)
       // this.filteredMissions = this.missions;
       // console.log("ADD THIS.MISSIONS",this.filteredMissions)
        //this.fetchMissions();
        //this.showAddModal = false;
        const addModal = Modal.getInstance(document.getElementById('addModal'));
        addModal.hide();
      } catch (error) {
        console.error('Erreur lors de l\'ajout de la mission:', error);
      }
    },
    async updateMission() {
      try {
        await api.updateMission(this.currentMission.id, this.currentMission);
        this.fetchMissions();
        this.showEditModal = false;
      } catch (error) {
        console.error('Erreur lors de la mise à jour de la mission:', error);
      }
    },
    async deleteMission() {
      try {
        await api.deleteMission(this.currentMission.id);
        this.fetchMissions();
        this.showDeleteModal = false;
      } catch (error) {
        console.error('Erreur lors de la suppression de la mission:', error);
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        // Implémentez la logique pour uploader le fichier
        // Cette partie dépend de la façon dont vous gérez le téléchargement de fichiers
      }
    },
    getDemandeOMInfo(id, field) {
      const demande = this.demandes_om.find(d => d.id === id);
      return demande ? demande[field] : '';
    },
    getVehiculeInfo(id, field) {
      //console.log("Get vehicule INfo ID",id)
      //console.log("Get info vehicules LIST ", this.vehicules)
      const vehicule = this.vehicules.find(v => v.id === 1);
      return vehicule ? vehicule[field] : '';
    },
    getVehiculeInfo1(id, field) {
  // Convertir l'ID en nombre s'il est une chaîne de caractères
  const vehiculeId = typeof id === 'string' ? parseInt(id, 10) : id;
  console.log("Get vehicule INfo ID", vehiculeId);
  console.log("Get info vehicules LIST ", this.vehicules);

  // Trouver le véhicule correspondant
  const vehicule = this.vehicules.find(v => v.id === vehiculeId);
  console.log("Get info vehiculeT ", vehicule);
  
  // Retourner le champ demandé ou 'NA' s'il n'est pas trouvé
  return vehicule ? vehicule[field] : 'NA';
},
getConducteurInfo(id, field) {
      const conducteurId = typeof id === 'string' ? parseInt(id, 10) : id;
      const conducteur = this.conducteurs.find(c => c.id === conducteurId);
      return conducteur ? conducteur.utilisateur[field] : 'n/a';
},
    viewMissionReports(missionId) {
      this.$router.push({ name: 'MissionReports', params: { missionId } });
    },
    viewMissionPositions(missionId) {
      this.$router.push({ name: 'MissionPositions', params: { missionId } });
    },
    /*sortTable(column) {
      this.filteredMissions.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
    },*/
    sortTable(column) {
        if (this.sortColumn === column) {
          this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortColumn = column;
          //console.log("sortColumn",this.sortColumn);
          this.sortOrder = 'asc';
        }
      },
    formatDate(dateString) {
        //const options = { year: 'numeric', month: 'long', day: 'numeric' };
        //return new Date(dateString).toLocaleDateString(undefined, options);
        return new Date(dateString).toISOString().substr(0, 10); 
      },
      /*getVehiculeInfo(vehiculeId, info) {
        const vehicule = this.vehicules.find(v => v.id === vehiculeId);
        return vehicule ? vehicule[info] : '';
      },
      getConducteurInfo(conducteurId, info) {
        const conducteur = this.conducteurs.find(c => c.id === conducteurId);
        return conducteur ? conducteur.utilisateur[info] : '';
      },
      viewMissionReports(missionId) {
        this.$router.push(`/missions/${missionId}/rapports`);
      },
      viewMissionPositions(missionId) {
        this.$router.push(`/missions/${missionId}/positions`);
      },*/
      getMissionStatus(mission) {
        const now = new Date();
        const start = new Date(mission.date_debut_prevue);
        const end = new Date(mission.date_fin_prevue);
  
        if (now < start) {
          return 'A venir';
        } else if (now > end) {
          return 'Terminée';
        } else {
          return 'En cours';
        }
      },
      truncateText(text, maxLength) {
        if (text.length > maxLength) {
          return text.slice(0, maxLength) + '...';
        }
        return text;
      }
  },
 /*watch: {
    searchQuery() {
      this.filterMissions();
    },
  },*/
  mounted() {
    //this.fetchUsers();
    //this.fetchMissions();
    this.fetchVehicules();
    this.fetchConducteurs();
    this.fetchDemandesOM();
    this.fetchMissions();
  },
};
</script>

<style>
/* Style personnalisé */
.card {
  margin-bottom: 20px;
}

.page-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .table th {
    cursor: pointer;
  }
  
  .truncated-text {
    width: 200px; /* La largeur maximale du conteneur */
    white-space: nowrap; /* Empêche le texte de s'étendre sur plusieurs lignes */
    overflow: hidden; /* Cache le texte débordant */
    text-overflow: ellipsis; /* Ajoute des points de suspension pour indiquer que le texte est tronqué */
  }

  .demandom{
    border: 2px solid rgb(210, 192, 255);
 /* outline: 0.5rem solid khaki;
  box-shadow: 0 0 0 2rem skyblue;*/
  border-radius: 12px;
  font: bold 1rem sans-serif;
  margin: 2rem;
  padding: 1rem;
  outline-offset: 0.5rem;
  }
</style>
